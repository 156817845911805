import {FC} from "react"
import {useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"

import {Link} from "../../components/Link"
import requestError from "../../services/requestError"
import {ISignUpValues, sendConfirmationAgain} from "./index"

const Sent: FC = () => {
  const {t} = useTranslation()

  const navigate = useNavigate()
  const {watch} = useFormContext<ISignUpValues>()
  const email = watch("email")
  const type = watch("type")

  const sendAgain = async () => {
    if (!type) {
      return navigate("/sign-up")
    }
    if (!email) {
      return navigate("/sign-up/" + type)
    }

    return sendConfirmationAgain({type, email})
      .then(() => toast.success(t("Signup_Sent_ResendToast")))
      .catch(requestError)
  }

  return (
    <>
      <div className={"flex flex-col gap-8 text-left"}>
        <div>
          {t("Signup_Sent_Text1")}
          <span className={"block font-bold text-cr-blue"}>{email}</span>
        </div>
        <div>{t("Signup_Sent_Text2")}</div>
        <div>
          {t("Signup_Sent_Text3")}{" "}
          <Link flipUnderline onClick={sendAgain}>
            {t("Signup_Sent_Resend")}
          </Link>
        </div>
      </div>
    </>
  )
}

export default Sent
