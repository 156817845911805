import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {zodResolver} from "@hookform/resolvers/zod"
import {useQueryClient} from "@tanstack/react-query"
import {isAxiosError} from "axios"
import {z} from "zod"

import {ButtonForm} from "../../../components/Button.tsx"
import {FieldErrorMessage} from "../../../components/fields/components.tsx"
import {InputField} from "../../../components/fields/Input.tsx"
import {HeroLayout} from "../../../components/HeroLayout.tsx"
import {LayoutCard} from "../../../components/LayoutCard.tsx"
import {Link} from "../../../components/Link.tsx"
import {useVerifyCompanyMutation} from "../../../queries/companies.ts"
import {queryKey} from "../../../services"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.ts"
import {useParam} from "../../../utils/hooks.tsx"
import {setFormErrorsFromAxios, validateNonemptyString, validatePassword} from "../../../utils/validation.ts"
import {getCreateSellingLink} from "../../CreateSalesCycle/utils.ts"

const validationSchema = z.object({
  company: z.object({
    name: validateNonemptyString(),
  }),
  company_user: z.object({
    password: validatePassword(),
    phone_number: validateNonemptyString(),
  }),
})

export const AccountCreatePage: React.FC = () => {
  const {t} = useTranslation()

  const methods = useForm<z.infer<typeof validationSchema>>({
    mode: "onTouched",
    resolver: zodResolver(validationSchema),
  })

  const token = useParam("confirmation_token", true)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const verifyCompanyMutation = useVerifyCompanyMutation()

  const handleSubmit = React.useCallback(async () => {
    if (!token) {
      return
    }

    const values = methods.getValues()

    try {
      await verifyCompanyMutation.mutateAsync({
        confirmation_token: token,
        company: {name: values.company.name},
        company_user: {
          password: values.company_user.password,
          password_confirmation: values.company_user.password,
          phone_number: values.company_user.phone_number,
          tac_agreement: true,
        },
      })

      emitGTMEvent({
        event: EGTMEvent.COMPANY_SIGNUP_VERIFY,
        company: values.company.name,
        phone: values.company_user.phone_number,
      })

      await queryClient.refetchQueries({queryKey: queryKey.userSettings})
      navigate(getCreateSellingLink())
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 401) {
        methods.setError("root", {message: t("Signup_Company_AccountCreatePage_NoToken_BadToken")})
        return
      }

      setFormErrorsFromAxios(e, methods.setError)
    }
  }, [methods, navigate, queryClient, t, token, verifyCompanyMutation])

  if (!token) {
    return (
      <HeroLayout>
        <LayoutCard title={t("Signup_Company_AccountCreatePage_NoToken_Title")}>
          {t("Signup_Company_AccountCreatePage_NoToken_Text")}
        </LayoutCard>
      </HeroLayout>
    )
  }

  return (
    <HeroLayout>
      <LayoutCard step={3} total={3} title={t("Signup_Company_AccountCreatePage_Title")}>
        <span>{t("Signup_Company_AccountCreatePage_Text")}</span>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <InputField
              name={"company.name"}
              small
              label={t("Signup_Company_AccountCreatePage_CompanyName")}
              placeholder={t("Signup_Company_AccountCreatePage_CompanyNamePlaceholder")}
            />
            <InputField
              name={"company_user.phone_number"}
              small
              label={t("Signup_Company_AccountCreatePage_PhoneNumber")}
              placeholder={t("Signup_Company_AccountCreatePage_PhoneNumberPlaceholder")}
            />
            <InputField
              name={"company_user.password"}
              type={"password"}
              Icon={false}
              small
              label={t("Signup_Company_AccountCreatePage_Password")}
              hideError
            />
            <ul className={"my-2 list-inside list-disc text-xs text-cr-grey-30"}>
              <Trans i18nKey={"Signup_Company_AccountCreatePage_PasswordRequirements"} components={{bullet: <li />}} />
            </ul>
            <FieldErrorMessage name={"company_user.password"} reserveSpace />

            <div className={"flex flex-col gap-4"}>
              <ButtonForm fullWidth type={"submit"}>
                {t("Signup_Company_AccountCreatePage_SubmitButton")}
              </ButtonForm>
            </div>
          </form>
        </FormProvider>
        <div className={"text-center text-sm"}>
          <Trans i18nKey={"Signup_Login"} components={{loginLink: <Link to={"/log-in"} flipUnderline />}} />
        </div>
      </LayoutCard>
    </HeroLayout>
  )
}
