import React from "react"

import enEnum from "../../locales/en-gb/translation.enum.json" assert {type: "json"}
import {TOption} from "../components/formElements/Dropdown"
import {i18n} from "../i18n"

export type TTranslatedEnums = typeof enEnum

export function enumTranslKey<
  TEnumName extends keyof TTranslatedEnums,
  TEnumValue extends (keyof TTranslatedEnums[TEnumName] & string) | string,
>(enumName: TEnumName, enumValue: TEnumValue) {
  return `enum:${enumName}_${enumValue}` as const
}

export function enumToOptions<Enum extends keyof TTranslatedEnums>(
  enumName: Enum
): Array<TOption<keyof TTranslatedEnums[Enum] & string>> {
  return (Object.keys(enEnum[enumName]) as Array<keyof TTranslatedEnums[Enum] & string>)
    .map(enumValue => {
      return {
        value: enumValue,
        title: i18n.t(enumTranslKey(enumName, enumValue)),
      }
    })
    .sort((A, B) => A.title.localeCompare(B.title))
}

export const commonTransComponents = {bold: <strong className={"font-semibold"} />, br: <br />, item: <span />}

export const BulletedTransList: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <div className={"grid grid-cols-[min-content_auto] items-baseline gap-x-3 gap-y-2"}>{children}</div>
}

export function removeTransTags(phrase: string, variables: {[key: string]: string} = {}): string {
  let result = phrase.replaceAll(/<\/?\w+ ?\/?>/g, "")

  for (const [key, value] of Object.entries(variables)) {
    result = result.replace(`{{${key}}}`, value)
  }

  return result
}
