import {useTranslation} from "react-i18next"
import {useLocation} from "react-router-dom"

import logoIcon from "../../../assets/logoIcon.svg"
import {useUserSettingsQuery} from "../../../queries/user.ts"
import NotificationsRenderer from "../../../utils/notify/NotificationsRenderer"
import {ButtonLink} from "../../Button"
import {UnstyledLink} from "../../Link"
import {TabItem, TabsContainer} from "../../Tabs"
import Avatar from "./Avatar"
import LogoAnimated from "./LogoAnimated"
import {navigation, UserTypes} from "./navigation.ts"
import {SlideOutMenu} from "./SlideOutMenu.tsx"

const pathsWHiddenAction = ["sales-form", "sales-strategy"]

const Header = () => {
  const {pathname} = useLocation()
  const {t} = useTranslation()

  const {isPending, data: {user} = {}} = useUserSettingsQuery()
  const userType = user?.type ?? UserTypes.Anonymous

  return (
    <header className={"fixed inset-x-0 top-0 z-30 flex justify-center border-b border-b-cr-grey-15 bg-white"}>
      <nav
        className={"mx-6 flex h-16 w-full max-w-screen-xl items-center justify-between lg:mx-8 xl:h-20"}
        aria-label={"Global"}
      >
        <div className={"flex gap-4 md:hidden"}>
          <UnstyledLink to={"/"} className={"mr-2"}>
            <img className={"h-8 w-auto"} src={logoIcon} alt={"Logo"} />
          </UnstyledLink>
          {!isPending && <SlideOutMenu userType={userType} />}
        </div>
        <TabsContainer className={"hidden items-stretch border-b-0 md:flex"}>
          <UnstyledLink to={"/"} className={"mr-2"}>
            <LogoAnimated />
          </UnstyledLink>

          {!isPending &&
            navigation[userType].map(item => {
              const isSelected = pathname.startsWith(item.href)

              return (
                <TabItem
                  key={item.name}
                  to={item.href}
                  isActive={isSelected}
                  {...("isExternal" in item && item.isExternal ? {target: "_blank"} : {})}
                >
                  <span>{item.name}</span>
                </TabItem>
              )
            })}
        </TabsContainer>
        {!isPending && (
          <div className={"relative flex flex-row items-center"}>
            {!pathsWHiddenAction.find(path => pathname.startsWith("/" + path)) &&
              userType === UserTypes.SalesPerson && (
                <ButtonLink
                  className={"mr-5 whitespace-nowrap"}
                  to={"https://closerocket.pipedrive.com"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  {t("Header_OpenPipeDrive")}
                </ButtonLink>
              )}
            {userType !== UserTypes.Anonymous && <NotificationsRenderer />}
            {userType !== UserTypes.Anonymous && <Avatar />}
          </div>
        )}
      </nav>
    </header>
  )
}

export default Header
