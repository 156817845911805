import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {AUserTypes} from "../../../services/types.generated"
import {getFullName} from "../../../utils"
import {useLoadPicture} from "../../../utils/hooks"
import {isCompanyUser} from "../../../utils/types"
import {Loading} from "../../Loading"
import {Menu} from "../../Menu"
import {MenuItem} from "../../Menu/MenuItem"
import {ProfileImage} from "../../ProfileImage.tsx"

const Avatar = () => {
  const {t} = useTranslation()
  const {user} = useUserSettingsOrLogout()
  const isSalesPersonUser = user.type === AUserTypes.SalesPerson

  let profileName: string | undefined
  let profileUrl = ""
  let profilePictureUrl = ""

  if (isCompanyUser(user)) {
    if (user.company.name) {
      profileName = user.company.name
    }
    profileUrl = `/company/${user.company.id}/profile`
    profilePictureUrl = user.company.profile_picture_thumbnail_url ?? profilePictureUrl
  }

  if (isSalesPersonUser) {
    profileName = getFullName(user)
    profileUrl = `/salesperson/${user.id}/profile`
    profilePictureUrl = user.profile_picture_thumbnail_url ?? profilePictureUrl
  }

  const {isLoading} = useLoadPicture(profilePictureUrl)

  return (
    <Menu
      Button={({isOpen}) => (
        <div
          className={twMerge([
            "ml-4 h-10 w-10 cursor-pointer overflow-hidden rounded-full bg-cr-grey-5 transition-all hover:ring hover:ring-cr-blue",
            isOpen && "ring ring-cr-blue-mid",
          ])}
        >
          {profilePictureUrl && isLoading ? (
            <div className={"size-full bg-cr-grey-5"} title={t("T_Your profile")}>
              <Loading size={"xs"} delayShow={false} />{" "}
            </div>
          ) : (
            <ProfileImage className={"size-full"} src={profilePictureUrl} title={t("T_Your profile")} />
          )}
        </div>
      )}
      items={[
        <div key={"signedInAs"} className={"p-2"}>
          {profileName ? (
            <>
              <p className={"truncate text-sm font-medium"} title={profileName}>
                {profileName}
              </p>
              <p className={"truncate text-xs text-cr-grey-50"} title={user.email}>
                {user.email}
              </p>
            </>
          ) : (
            <>
              <p className={"text-sm"}>{t("T_Signed in as")}</p>
              <p className={"truncate text-sm font-medium"} title={user.email}>
                {user.email}
              </p>
            </>
          )}
        </div>,
        profileUrl && (
          <MenuItem key={"profile"} variant={"primary"} ghost to={profileUrl}>
            {t("T_Your profile")}
          </MenuItem>
        ),
        isSalesPersonUser && (
          <MenuItem
            key={"learningMaterials"}
            variant={"primary"}
            ghost
            to={`/salesperson/${user.id}/learning-materials`}
          >
            {t("SalesPersonLearning_DocumentTitle")}
          </MenuItem>
        ),
        <MenuItem key={"logout"} variant={"error"} ghost to={"/log-out"}>
          {t("T_Log out")}
        </MenuItem>,
      ]}
    />
  )
}

export default Avatar
