import {FieldPath} from "react-hook-form"
import {UseFormSetError} from "react-hook-form/dist/types/form"
import {isAxiosError} from "axios"
import {EnumLike, z, ZodFirstPartySchemaTypes} from "zod"

import {i18n} from "../i18n"
import requestError from "../services/requestError"
import {TAxiosErrorWithResponse} from "../services/types"
import {addHttpToURL, getDeepValue} from "./index"

export const requiredFieldMessage = i18n.t("Validation_Required")
export const invalidEmailMessage = i18n.t("Validation_InvalidEmail")
export const invalidPhoneMessage = i18n.t("Validation_InvalidPhone")
export const invalidURLMessage = i18n.t("Validation_InvalidURL")
export const invalidPasswordMessage = i18n.t("Validation_InvalidPassword")

function isUrl(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}
export const validateUrl = ({message = invalidURLMessage, requireProtocol = false} = {}) => {
  return z.string().refine(val => isUrl(val) || (!requireProtocol && isUrl(addHttpToURL(val))), {message})
}

export const validateNonemptyString = (message = requiredFieldMessage) =>
  z
    .string({required_error: message})
    .nullable()
    .transform(t => t?.trim() ?? "")
    .pipe(z.string().min(1, message))

export const validatePassword = (message = invalidPasswordMessage) =>
  z
    .string({required_error: message})
    .regex(/.*[a-z].*/, message)
    .regex(/.*[A-Z].*/, message)
    .regex(/.*[0-9].*/, message)
    .pipe(z.string().min(8, message))

export const validateRequiredNumber = (message = requiredFieldMessage) =>
  z.number({required_error: message, invalid_type_error: message})

export const validatePhoneNumber = (message = invalidPhoneMessage) => validateNonemptyString(message)

export const validateEmail = (message = invalidEmailMessage) => z.string({required_error: message}).email(message)

export const validateNonemptyArray = <T extends ZodFirstPartySchemaTypes>(
  memberValidation: T,
  requiredMessage = requiredFieldMessage
) =>
  z
    .array(memberValidation, {required_error: requiredMessage, invalid_type_error: requiredMessage})
    .nonempty(requiredMessage)

export const validateNativeEnum = <T extends EnumLike>(nativeEnum: T) =>
  z.nativeEnum(nativeEnum, {invalid_type_error: requiredFieldMessage, required_error: requiredFieldMessage})

export type TApiValidationErrorObject<T extends Record<string, any> = any> = {
  [name in keyof T]?: T[name] extends Record<string, any> ? string[] | TApiValidationErrorObject<T[name]> : string[]
}

type TFormValidationErrorLeaf = {message: string}
type TFormValidationErrorArray<T> = T extends [...any[]]
  ? {
      [index in keyof T]: TFormValidationError<T[index]>
    }
  : never
type TFormValidationErrorObject<T> =
  T extends Record<string, any>
    ? {
        [name in keyof T]?: TFormValidationError<T[name]>
      }
    : never

export type TFormValidationError<T = any> =
  | TFormValidationErrorLeaf
  | TFormValidationErrorArray<T>
  | TFormValidationErrorObject<T>

const isFormValidationErrorLeaf = (value: unknown): value is TFormValidationErrorLeaf => {
  if (typeof value !== "object" || value === null) {
    return false
  }

  return "message" in value && typeof value.message === "string"
}

export const isFormValidationError = (value: unknown): value is TFormValidationError => {
  if (typeof value !== "object" || value === null) {
    return false
  }

  if (isFormValidationErrorLeaf(value)) {
    return true
  }

  const subValues = Object.values(value)

  return subValues.length > 0 && subValues.every(subValue => isFormValidationError(subValue))
}

const isApiValidationErrorObject = (e: unknown): e is TApiValidationErrorObject => {
  if (typeof e !== "object" || e === null) {
    return false
  }

  return Object.values(e).every(value => {
    return (
      isApiValidationErrorObject(value) || (Array.isArray(value) && value.every(message => typeof message === "string"))
    )
  })
}
export const isAxiosValidationError = (
  e: unknown
): e is TAxiosErrorWithResponse<{
  errors: TApiValidationErrorObject
}> => {
  return (
    isAxiosError(e) &&
    (isApiValidationErrorObject(e.response?.data.errors) || isApiValidationErrorObject(e.response?.data))
  )
}

export const getApiValidationMessages = <T extends TApiValidationErrorObject>(
  validationObject: T,
  field: keyof T
): string[] => {
  const fieldValidation = validationObject[field]

  if (Array.isArray(fieldValidation)) {
    return fieldValidation
  }

  if (!fieldValidation) {
    return []
  }

  return (Object.keys(fieldValidation) as Array<keyof typeof fieldValidation>).flatMap(subfield =>
    getApiValidationMessages(fieldValidation, subfield)
  )
}

export const getFormValidationMessages = <T extends TFormValidationError>(validationObject: T): string[] => {
  if (isFormValidationErrorLeaf(validationObject)) {
    return [validationObject.message]
  }

  return Object.values(validationObject).flatMap(validation => getFormValidationMessages(validation))
}

export function setFormErrorsFromAxios(axiosError: unknown, setErrorFn: UseFormSetError<any>, entityName?: string) {
  if (!isAxiosValidationError(axiosError)) {
    return requestError(axiosError)
  }

  const {base: rootErrors, ...errors} = axiosError.response.data.errors ?? axiosError.response.data

  if (rootErrors) {
    setFormErrorsFromApiValidationObject({root: rootErrors}, setErrorFn)
  }

  const entityErrors = getDeepValue(errors, entityName)
  if (entityName && isApiValidationErrorObject(entityErrors)) {
    setFormErrorsFromApiValidationObject(entityErrors, setErrorFn)
  } else {
    setFormErrorsFromApiValidationObject(errors, setErrorFn)
  }
}

export function setFormErrorsFromApiValidationObject<T extends Record<string, any>>(
  validationObject: TApiValidationErrorObject<T>,
  setErrorFn: UseFormSetError<T>,
  path = ""
) {
  for (const [key, value] of Object.entries(validationObject)) {
    if (Array.isArray(value)) {
      const fieldPath = (path.length ? `${path}.${key}` : key) as FieldPath<T>

      setErrorFn(fieldPath, {message: value.join("\n")})
      continue
    }

    setFormErrorsFromApiValidationObject(value, setErrorFn, `${path}.${key}` as FieldPath<T>)
  }
}
