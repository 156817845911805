import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {Outlet, useLocation, useNavigate} from "react-router-dom"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import {HeroLayout} from "../../components/HeroLayout.tsx"
import {LayoutCard} from "../../components/LayoutCard.tsx"
import {Link} from "../../components/Link"
import {Loading} from "../../components/Loading.tsx"
import api from "../../services"
import requestError from "../../services/requestError"
import {AUserTypes} from "../../services/types.generated"
import {EGTMEvent, emitGTMEvent} from "../../utils/gtm"
import {useDocumentTitle} from "../../utils/hooks"
import {
  getApiValidationMessages,
  isAxiosValidationError,
  setFormErrorsFromAxios,
  validateNonemptyString,
} from "../../utils/validation"

export interface ISignUpValues {
  type: AUserTypes
  email: string
}

const locationPathnameSent = "/sign-up/sent"

const validationSchema = z.object({
  email: validateNonemptyString(),
})

export const sendConfirmationAgain = ({type, email}: ISignUpValues) =>
  type === AUserTypes.SalesPerson
    ? api.salesPeople.confirmationCreate({sales_person: {email}})
    : api.companyUsers.confirmationCreate({company_user: {email}})

const SignUp = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Sign up"))

  const methods = useForm<ISignUpValues>({mode: "onTouched", resolver: zodResolver(validationSchema)})
  const navigate = useNavigate()
  const pathname = useLocation().pathname
  const sent = locationPathnameSent === pathname

  const onSend = async () => {
    const {type, email} = methods.getValues()

    const signUpPromise =
      type === AUserTypes.SalesPerson
        ? api.salesPeople.salesPeopleCreate({sales_person: {email}})
        : api.companyUsers.companyUsersCreate({company_user: {email}})

    try {
      await signUpPromise
      emitGTMEvent({
        event: type === AUserTypes.SalesPerson ? EGTMEvent.SALES_SIGNUP_SEND : EGTMEvent.COMPANY_SIGNUP_SEND,
        email,
      })
      navigate(locationPathnameSent)
    } catch (error) {
      if (!isAxiosValidationError(error)) {
        return requestError(error)
      }

      const emailErrorMessages = getApiValidationMessages(error.response.data.errors, "email")
      const isEmailTaken = emailErrorMessages.some(
        // TODO: this approach is terrible but has to be fixed on the BE
        message => message === "has already been taken" || message === "ste už použili"
      )

      if (isEmailTaken) {
        try {
          await sendConfirmationAgain({type, email})
          navigate(locationPathnameSent)
        } catch (error2) {
          setFormErrorsFromAxios(error2, methods.setError)
        }
      } else {
        setFormErrorsFromAxios(error, methods.setError)
      }
    }
  }

  const alternativeAction = sent ? (
    <span>
      {t("T_Need help?")}{" "}
      <Link to={t("Url_Contact")} flipUnderline target={"_blank"}>
        {t("T_Contact us")}
      </Link>
    </span>
  ) : null

  return (
    <HeroLayout rightAction={alternativeAction}>
      <LayoutCard title={sent ? t("Signup_Sent_Header") : t("Signup_Join CloseRocket")}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSend)}>
            <React.Suspense fallback={<Loading size={"xl"} />}>
              <Outlet />
            </React.Suspense>
          </form>
        </FormProvider>
        <div className={"text-center text-sm"}>
          <Trans i18nKey={"Signup_Login"} components={{loginLink: <Link to={"/log-in"} flipUnderline />}} />
        </div>
      </LayoutCard>
    </HeroLayout>
  )
}

export default SignUp
