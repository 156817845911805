import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate, useSearchParams} from "react-router-dom"
import {BriefcaseIcon, PhoneIcon} from "@heroicons/react/24/outline"
import {zodResolver} from "@hookform/resolvers/zod"
import {useQueryClient} from "@tanstack/react-query"
import {z} from "zod"

import {ButtonForm} from "../components/Button"
import {CheckboxConnected} from "../components/fields/Checkbox"
import {ErrorMessage, FieldErrorMessage} from "../components/fields/components"
import {InputField} from "../components/fields/Input"
import {HeroLayout} from "../components/HeroLayout.tsx"
import {LayoutCard} from "../components/LayoutCard.tsx"
import {Link} from "../components/Link"
import {Loading} from "../components/Loading"
import {i18n} from "../i18n"
import {usePendingConfirmationQuery} from "../queries/pendingConfirmations"
import api, {queryKey} from "../services"
import {AUserTypes} from "../services/types.generated"
import {getFullName} from "../utils"
import {EGTMEvent, emitGTMEvent} from "../utils/gtm"
import {useDocumentTitle, useParam} from "../utils/hooks"
import {setFormErrorsFromAxios, validateNonemptyString, validatePhoneNumber} from "../utils/validation"

type TVerificationValues = {
  company: {
    name: string
  }
  first_name: string
  last_name: string
  phone_number: string
  password: string
  tac_agreement: boolean
  email?: string
}

const searchParamsTokenKey = "confirmation_token"

const validationSchemaBase = z.object({
  phone_number: validatePhoneNumber(),
  password: validateNonemptyString(),
  tac_agreement: z.literal(true, {
    errorMap: () => ({message: i18n.t("T_You need to agree to the Terms of service")}),
  }),
})

const validationSchemaCompany = validationSchemaBase.and(
  z.object({
    company: z.object({
      name: validateNonemptyString(),
    }),
  })
)
const validationSchemaSales = validationSchemaBase.and(
  z.object({
    first_name: validateNonemptyString(),
    last_name: validateNonemptyString(),
  })
)

export const Verification: React.FC<{type: AUserTypes}> = ({type}) => {
  const token = useParam("confirmation_token")

  const {data, isPending} = usePendingConfirmationQuery(token)

  if (isPending) {
    return <Loading size={"xl"} />
  }

  return <VerificationLoaded type={type} data={data?.pending_confirmation} />
}

const VerificationLoaded: React.FC<{type: AUserTypes; data: Partial<TVerificationValues> | undefined}> = ({
  type,
  data,
}) => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Sign up"))

  const queryClient = useQueryClient()
  const methods = useForm<TVerificationValues>({
    defaultValues: data,
    mode: "onTouched",
    resolver: zodResolver(type === AUserTypes.CompanyUser ? validationSchemaCompany : validationSchemaSales),
  })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get(searchParamsTokenKey) ?? ""

  const onSubmit = async ({company, ...values}: TVerificationValues) => {
    try {
      const data = {confirmation_token: token}

      if (type === AUserTypes.SalesPerson) {
        await api.salesPeople.confirmationPartialUpdate({
          sales_person: {...values, password_confirmation: values.password},
          ...data,
        })
        emitGTMEvent({
          event: EGTMEvent.SALES_SIGNUP_VERIFY,
          name: getFullName(values),
          phone: values.phone_number,
        })
      } else {
        await api.companyUsers.confirmationPartialUpdate({
          company_user: {...values, password_confirmation: values.password},
          company,
          ...data,
        })
        emitGTMEvent({
          event: EGTMEvent.COMPANY_SIGNUP_VERIFY,
          company: company.name,
          phone: values.phone_number,
        })
      }

      await queryClient.refetchQueries({queryKey: queryKey.userSettings})
      navigate("/")
    } catch (e) {
      setFormErrorsFromAxios(e, methods.setError)
    }
  }

  return (
    <HeroLayout>
      <LayoutCard title={t("T_Verification")}>
        {methods.formState.errors.email && (
          <div className={"mb-8 text-left"}>
            <ErrorMessage
              error={{message: t("Verification_Your email has already been confirmed. Please try signing in.")}}
            />
          </div>
        )}

        <p className={"text-cr-grey-50"}>
          {t("Verification_Your email has been successfully verified, please provide the following information")}
        </p>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {type === AUserTypes.CompanyUser && (
              <InputField Icon={BriefcaseIcon} label={t("T_Company name")} name={"company.name"} />
            )}
            {type === AUserTypes.SalesPerson && (
              <>
                <InputField label={t("T_First name")} name={"first_name"} />
                <InputField label={t("T_Last name")} name={"last_name"} />
              </>
            )}
            <InputField Icon={PhoneIcon} label={t("T_Phone number")} name={"phone_number"} />
            <InputField
              type={"password"}
              label={t("T_Password")}
              name={"password"}
              legend={t("Verification_8+ characters long, contain at least one uppercase letter and number")}
            />
            <div className={"mb-2"}>
              <CheckboxConnected name={"tac_agreement"}>
                {t("Verification_I agree with")}{" "}
                <Link
                  to={
                    type === AUserTypes.SalesPerson
                      ? t("Url_TermsAndConditions_Salesperson")
                      : t("Url_TermsAndConditions_Company")
                  }
                  target={"_blank"}
                >
                  {t("T_Terms and Conditions")}
                </Link>
              </CheckboxConnected>
              <FieldErrorMessage name={"tac_agreement"} reserveSpace />
            </div>
            <ButtonForm fullWidth>{t("Verification_Join")}</ButtonForm>
          </form>
        </FormProvider>
      </LayoutCard>
    </HeroLayout>
  )
}
