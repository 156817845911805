import {FC} from "react"
import {useTranslation} from "react-i18next"
import {BriefcaseIcon, BuildingOfficeIcon} from "@heroicons/react/24/outline"

import {Link} from "../../components/Link"
import {EGTMEvent, emitGTMEvent} from "../../utils/gtm"

const Pick: FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <p className={"mb-12 text-left text-cr-grey-50"}>
        {t(
          "Signup_Become a part of the biggest sales community in the world connecting amazing companies with salespeople globally."
        )}
      </p>
      <div className={"grid grid-cols-1 gap-8 text-xl font-medium sm:grid-cols-2"}>
        <Link
          to={"/sign-up/company"}
          onClick={() => emitGTMEvent({event: EGTMEvent.COMPANY_SIGNUP_START})}
          className={"btn-radio-block px-8 py-6 text-center text-cr-grey-90"}
          noUnderline
        >
          <BuildingOfficeIcon className={"mx-auto mb-4 h-12 w-12"} />
          {t("Signup_Join as a")}
          <br />
          {t("Signup_company")}
        </Link>

        <Link
          to={"/sign-up/sales"}
          onClick={() => emitGTMEvent({event: EGTMEvent.SALES_SIGNUP_START})}
          className={"btn-radio-block px-8 py-6 text-center text-cr-grey-90"}
          noUnderline
        >
          <BriefcaseIcon className={"mx-auto mb-4 h-12 w-12"} />
          {t("Signup_Join as a")}
          <br />
          {t("Signup_sales talent")}
        </Link>
      </div>
    </>
  )
}

export default Pick
