import {FC, useEffect} from "react"
import {useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"

import {ButtonForm} from "../../components/Button"
import {InputField} from "../../components/fields/Input"
import {AUserTypes} from "../../services/types.generated"

const Email: FC<{type: AUserTypes}> = ({type}) => {
  const {t} = useTranslation()

  const {control, setValue} = useFormContext()

  useEffect(() => {
    setValue("type", type)
  }, [setValue, type])

  return (
    <>
      <p className={"mb-8 text-cr-grey-50"}>{t("Signup_We will send you a verification email to your account")}</p>

      <InputField label={t("T_E-mail")} type={"email"} name={"email"} placeholder={t("Signup_you@example.com")} />

      <ButtonForm fullWidth control={control} type={"submit"}>
        {t("T_Continue")}
      </ButtonForm>
    </>
  )
}

export default Email
