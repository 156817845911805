import {Navigate} from "react-router-dom"

import {Loading} from "../components/Loading"
import {useUserSettingsQuery} from "../queries/user.ts"
import {AUserTypes} from "../services/types.generated"

const Landing = () => {
  const {data, isPending} = useUserSettingsQuery()
  const type = data?.user.type
  if (isPending) {
    return <Loading size={"xl"} />
  }
  if (type === AUserTypes.CompanyUser) {
    return <Navigate replace to={"/dashboard"} />
  }
  if (type === AUserTypes.SalesPerson) {
    return <Navigate replace to={"/dashboard-salesperson"} />
  }
  if (type === AUserTypes.Admin) {
    return <Navigate replace to={"/admin"} />
  }
  return <Navigate replace to={"/log-in"} />
}

export default Landing
