import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {ChevronLeftIcon} from "@heroicons/react/20/solid"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import {ButtonForm} from "../../../components/Button.tsx"
import {CheckboxConnected} from "../../../components/fields/Checkbox.tsx"
import {FieldErrorMessage} from "../../../components/fields/components.tsx"
import {InputField} from "../../../components/fields/Input.tsx"
import {HeroLayout} from "../../../components/HeroLayout.tsx"
import {LayoutCard} from "../../../components/LayoutCard.tsx"
import {Link} from "../../../components/Link.tsx"
import {i18n} from "../../../i18n.ts"
import {useRegisterCompanyMutation, useRegisterCompanyResendMutation} from "../../../queries/companies.ts"
import requestError from "../../../services/requestError.tsx"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.ts"
import {useDocumentTitle} from "../../../utils/hooks.tsx"
import {commonTransComponents} from "../../../utils/i18n.tsx"
import {
  getApiValidationMessages,
  isAxiosValidationError,
  requiredFieldMessage,
  setFormErrorsFromAxios,
  validateEmail,
} from "../../../utils/validation.ts"

const validationSchema = z.object({
  email: validateEmail(),
  tacAgreement: z.literal(true, {
    errorMap: () => ({message: requiredFieldMessage}),
  }),
})

export const EmailFormPage: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("Signup_DocumentTitle"))

  const [sentToEmail, setSentToEmail] = React.useState<null | string>(null)

  return sentToEmail ? <EmailSent email={sentToEmail} /> : <EmailForm onSent={setSentToEmail} />
}

export const EmailForm: React.FC<{onSent: (email: string) => void}> = ({onSent}) => {
  const {t} = useTranslation()

  const methods = useForm<{email: string}>({mode: "onTouched", resolver: zodResolver(validationSchema)})

  const registerCompanyMutation = useRegisterCompanyMutation()
  const registerCompanyResendMutation = useRegisterCompanyResendMutation()

  const handleSubmit = React.useCallback(async () => {
    const {email} = methods.getValues()

    try {
      await registerCompanyMutation.mutateAsync({email})
      emitGTMEvent({
        event: EGTMEvent.COMPANY_SIGNUP_SEND,
        email,
      })
      onSent(email)
    } catch (error) {
      if (!isAxiosValidationError(error)) {
        return requestError(error)
      }

      const emailErrorMessages = getApiValidationMessages(error.response.data.errors, "email")
      const isEmailTaken = emailErrorMessages.some(
        // TODO: this approach is terrible but has to be fixed on the BE
        message => message === "has already been taken" || message === "ste už použili"
      )

      if (isEmailTaken) {
        try {
          await registerCompanyResendMutation.mutateAsync({email})
          onSent(email)
        } catch (realError) {
          setFormErrorsFromAxios(realError, methods.setError)
        }
      } else {
        setFormErrorsFromAxios(error, methods.setError)
      }
    }
  }, [methods, onSent, registerCompanyMutation, registerCompanyResendMutation])

  return (
    <HeroLayout
      leftAction={
        <Link to={"/sign-up"} variant={"neutral"} flipUnderline className={"inline-flex items-baseline gap-1"}>
          <ChevronLeftIcon className={"size-5 self-center"} />
          <span>{i18n.t("Signup_BackLink")}</span>
        </Link>
      }
    >
      <LayoutCard step={1} total={3} title={t("Signup_Company_EmailFormPage_Title")}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <InputField
              name={"email"}
              type={"email"}
              small
              label={t("Signup_Company_EmailFormPage_EmailLabel")}
              placeholder={t("Signup_Company_EmailFormPage_EmailPlaceholder")}
            />

            <div className={"my-2"}>
              <CheckboxConnected name={"tacAgreement"}>
                <Trans
                  i18nKey={"Signup_Company_EmailFormPage_Terms"}
                  components={{
                    tacLink: <Link flipUnderline to={t("Url_TermsAndConditions_Company")} />,
                    privacyLink: <Link flipUnderline to={t("Url_PrivacyPolicy")} />,
                  }}
                />
              </CheckboxConnected>
              <FieldErrorMessage name={"tacAgreement"} reserveSpace />
            </div>

            <ButtonForm fullWidth type={"submit"}>
              {t("Signup_Company_EmailFormPage_SubmitButton")}
            </ButtonForm>
          </form>
        </FormProvider>
        <div className={"text-center text-sm"}>
          <Trans i18nKey={"Signup_Login"} components={{loginLink: <Link to={"/log-in"} flipUnderline />}} />
        </div>
      </LayoutCard>
    </HeroLayout>
  )
}

export const EmailSent: React.FC<{email: string}> = ({email}) => {
  const {t} = useTranslation()

  const registerCompanyResendMutation = useRegisterCompanyResendMutation()

  const handleResend = React.useCallback(async () => {
    try {
      await registerCompanyResendMutation.mutateAsync({email})

      toast.success(t("Signup_Company_EmailFormPage_Sent_ResentToast"))
    } catch {
      toast.error(t("Signup_Company_EmailFormPage_Sent_ResentErrorToast"))
    }
  }, [email, registerCompanyResendMutation, t])

  return (
    <HeroLayout>
      <LayoutCard step={2} total={3} title={t("Signup_Company_EmailFormPage_Sent_Title")}>
        <div>
          <Trans
            i18nKey={"Signup_Company_EmailFormPage_Sent_Text"}
            values={{email}}
            components={{...commonTransComponents, highlight: <strong className={"font-semibold text-cr-blue"} />}}
          />
        </div>
        <div className={"flex items-baseline gap-1"}>
          {t("Signup_Company_EmailFormPage_Sent_ResendText")}{" "}
          <Link disabled={registerCompanyResendMutation.isPending} onClick={handleResend}>
            {t("Signup_Company_EmailFormPage_Sent_ResendButton")}
          </Link>
        </div>
      </LayoutCard>
    </HeroLayout>
  )
}
