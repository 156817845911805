import {useQuery} from "@tanstack/react-query"

import api, {queryKey} from "../services"

export const usePendingConfirmationQuery = (token: string) => {
  return useQuery({
    queryKey: queryKey.pendingConfirmation(token),
    queryFn: async () => (await api.pendingConfirmations.pendingConfirmationsDetail(token)).data,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  })
}
