import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {isAxiosError} from "axios"

import {getCurrentLanguage} from "../i18n.ts"
import api, {queryKey, TRollbarUserInfo} from "../services"
import {ASettings, Users} from "../services/types.generated.ts"
import {getFullName} from "../utils"
import {setLSItem} from "../utils/localStorage"
import {isCompanyUser} from "../utils/types.ts"

export function useUserSettingsQuery() {
  return useQuery({
    queryKey: queryKey.userSettings,
    queryFn: fetchUserSettings,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    throwOnError: false,
    retry: (i, error) => {
      if (isAxiosError(error) && error.response?.status === 401) {
        return false
      }
      return i < 3
    },
  })
}

export function useUserSettingsOrLogout() {
  const queryClient = useQueryClient()

  const settings = queryClient.getQueryData<ASettings>(queryKey.userSettings)

  if (!settings) {
    window.location.replace("/log-in")
    throw new Error("User is not logged in")
  }
  return settings
}

export async function fetchUserSettings() {
  return (await api.settings.settingsList()).data.settings
}

export type TLogInValues = NonNullable<Users.SignInCreate.RequestBody["user"]>

export function useLoginMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (user: TLogInValues) => {
      await api.users.signInCreate({user})
      await queryClient.invalidateQueries()
      await queryClient.prefetchQuery({queryKey: queryKey.userSettings, queryFn: fetchUserSettings})

      const userData = queryClient.getQueryData<ASettings>(queryKey.userSettings)

      if (userData) {
        setLSItem("lastUser", {
          id: userData.user.id,
          username: isCompanyUser(userData.user) ? userData.user.company.name : getFullName(userData.user),
          email: user.email,
          type: userData.user.type,
          language: getCurrentLanguage(),
        } satisfies TRollbarUserInfo)
      }
    },
  })
}

export type TPasswordResetRequestValues = NonNullable<Users.PasswordCreate.RequestBody["user"]>

export function usePasswordResetRequestMutation() {
  return useMutation({
    mutationFn: (user: TPasswordResetRequestValues) => api.users.passwordCreate({user}),
  })
}

export type TNewPasswordValues = NonNullable<Users.PasswordUpdate.RequestBody["user"]>

export function useSetNewPasswordMutation() {
  return useMutation({
    mutationFn: (user: TNewPasswordValues) => api.users.passwordUpdate({user}),
  })
}
