import React from "react"
import {Outlet, useLocation} from "react-router-dom"
import {twMerge} from "tailwind-merge"

import {LogoutIfUnauthorized} from "../../services/LogoutIfUnauthorized"
import {Loading} from "../Loading.tsx"
import {MaintenanceChecker} from "../MaintenanceChecker"
import {Footer} from "./Footer"
import Header from "./Header"

const noHeaderPaths = [
  "log-in",
  "log-out",
  "sign-up",
  "forgotten-password",
  "verification",
  "privacy-request",
  "create-selling",
]

const Layout = () => {
  const pathname = useLocation().pathname
  const headerHidden = pathname === "/" || noHeaderPaths.find(path => pathname.startsWith(path, 1)) !== undefined

  return (
    <MaintenanceChecker>
      {!headerHidden && <Header />}
      <div
        className={twMerge("flex min-h-screen flex-col justify-between bg-cr-white", !headerHidden && "pt-16 xl:pt-20")}
      >
        <div className={"flex grow flex-col"}>
          <React.Suspense fallback={<Loading size={"xl"} />}>
            <Outlet />
          </React.Suspense>
        </div>
        {!headerHidden && <Footer />}
      </div>

      <LogoutIfUnauthorized />
    </MaintenanceChecker>
  )
}

export default Layout
