import React from "react"
import {twMerge} from "tailwind-merge"

import {pickProps} from "../../utils/types"
import {FieldErrorMessage} from "./components"

export type TFieldLabelProps = {
  name: string
  label: React.ReactNode
  legend?: React.ReactNode
  required?: boolean
  hideError?: boolean
  labelClassName?: string
  containerClassName?: string
  errorMessageProps?: Partial<React.ComponentProps<typeof FieldErrorMessage>>
  children?: React.ReactNode
  small?: boolean
}

export const FieldLabel: React.FC<TFieldLabelProps> = ({
  name,
  required,
  label,
  legend,
  hideError,
  labelClassName,
  containerClassName,
  children,
  errorMessageProps,
  small,
}) => {
  return (
    <div className={twMerge(["flex flex-col gap-2 text-left", containerClassName])}>
      <label
        htmlFor={name}
        className={twMerge([
          "text-lg font-semibold",
          required && "required-after",
          small && "text-base",
          labelClassName,
        ])}
      >
        {label}
      </label>
      {legend && <div className={"-mt-2 text-sm font-normal text-cr-grey-50"}>{legend}</div>}
      <div>{children}</div>
      {!hideError && <FieldErrorMessage name={name} reserveSpace {...errorMessageProps} />}
    </div>
  )
}

export const pickFieldLabelProps = pickProps<Omit<TFieldLabelProps, "children">>({
  name: true,
  hideError: true,
  label: true,
  legend: true,
  required: true,
  errorMessageProps: true,
  labelClassName: true,
  containerClassName: true,
  small: true,
})
